import React, { useState } from "react"

import styled from "styled-components"

import { FadeIn } from "../Animations"
import TransitionLink from "gatsby-plugin-transition-link"
import BlackLayer from "./BlackLayer"
import { StaticImage } from "gatsby-plugin-image"
import { FilmBg } from "./FilmBg"
import { FilmTitles } from "./FilmTitles"
import FilmHeader from "./FilmHeader"

const StyledFilms = styled.div`
  display: none;
  @media (min-width: 800px) {
    opacity: 0;
    animation: ${FadeIn} 1s 0.4s linear forwards;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
  }
  transition: opacity ease 0.5s 0.5s !important;

  ${props =>
    props.isLinkClicked
      ? " #titles{opacity: 0 !important;} .film-background{opacity: 0 !important;}"
      : ""}
  position: fixed;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  .film-background {
    transition: opacity ease-in 0.45s;
  }

  ${BlackLayer} {
    /* default rule */
    opacity: 0;
  }

  &:hover {
    ${BlackLayer}, #tocontact {
      &:not(:hover) {
        opacity: 0;
      }
    }
  }
  .dash {
    font-weight: 500;
  }
  .hidden {
    display: none;
  }
  ${({ picture }) => handleTitleHovered(picture)};
`
const MainText = styled.div`
  position: relative;
  width:840px;
  margin: auto;
  transition: opacity ease 1s 0s !important;

  opacity: ${props => (props.isLinkClicked ? "0 !important" : "1")};
  z-index: 2;
  text-align: center;
      #filmlist, #header {
        font-size: 13px;
        text-align:center;
        line-height:14px;
        font-weight:200 !important;
      }
      
      #tocontact {
        display:inline;
        font-weight:600;
        margin-bottom:0;
        text-align:left;
        display: inline-block;
        border-bottom: 2px solid transparent;
        line-height: 15px;
        &:hover{
          border-color: black;        }
      }


    }

    @media (min-width: 1300px) {
      width: 990px;
      #tocontact, #header  {
        font-size: 16px;
        line-height: 18px;
      }
      #filmlist {
        font-size: 16px;
        line-height: 20px;
        margin-bottom:12px;
      }
    }
    @media (min-width: 1500px) {
      width: 1120px;
      #tocontact, #header  {
        font-size: 18px;
        line-height: 18px;
      }
      #filmlist {
        font-size: 18px;
        line-height: 20px;
        margin-bottom:12px;
      }
    }

    transition-property: opacity;
    transition-timing-function: ease-in;
    ${props =>
      props.contactShown
        ? "opacity:0; pointer-events:none; transition-duration: 0.7s; z-index:0; transition-delay: 0s;"
        : "opacity:1; pointer-events:auto; transition-duration: 0.7s; z-index:999; transition-delay: 0.5s;"}
  
  ul {
    margin: 0;
    padding: 0;
  }

  li {
    display: inline;
  }

  #tocontact {
    cursor: pointer;
   
  }

 

  #main-text,
  .film-title,
  .dash,
  #tocontact {
    opacity: 1 !important;
    transition: opacity 0.5s ease;
  }
  &:hover {
    .dash {
      opacity: 0.25 !important;
    }
    .film-title,
    #tocontact {
      opacity: 0.25 !important;

      &:hover {
        opacity: 1 !important;
      }
    }
  }
`

//function which renders different css rules depending on the hovered title
const handleTitleHovered = picture => {
  switch (picture) {
    case "none":
      return `.film-background { opacity: 0 ;}  ;}`
    case "leaving":
      return `.film-background { opacity: 0 ;}  ;}#main-text { opacity: 0 ;}  ;}`
    case "mercedes":
      return `.film-background { opacity: 0 ;}  ;}#mercedespic { opacity: 1 ;}  ;}`
    case "audemars":
      return `.film-background { opacity: 0; } #audemarspic { opacity: 1; } ;}`
    case "travis":
      return `.film-background { opacity: 0; } #travispic { opacity: 1; } ;}`
    case "mia":
      return `.film-background { opacity: 0; } #miapic { opacity: 1; } ;}`
    case "kanye":
      return `.film-background { opacity: 0; } #kanyepic { opacity: 1; } ;}`
    case "kanyetwo":
      return `.film-background { opacity: 0; } #kanyetwopic { opacity: 1; } ;}`
    case "kanyethree":
      return `.film-background { opacity: 0; } #kanyethreepic { opacity: 1; } ;}`
    case "beadadoobee":
      return `.film-background { opacity: 0; } #beadadoobeepic { opacity: 1; } ;}`
    case "rlgrime":
      return `.film-background { opacity: 0; } #rlgrimepic { opacity: 1; } ;}`
    case "nike":
      return `.film-background { opacity: 0; } #nikepic { opacity: 1; } ;}`
    case "flatbush":
      return `.film-background { opacity: 0; } #flatbushpic { opacity: 1; } }`

    default:
      return ""
  }
}

//Function which changes the state of the film background
export default function Films(props) {
  const [hoveredTitle, toggleHover] = useState("none")
  const [isLinkClicked, toggleClick] = useState(false)

  function clickLink() {
    toggleClick(true)
  }

  const imgsettings = {
    className: "image",
    height: 1600,
    quality: 80,
    objectFit: "cover",
    objectPosition: "50% 50%",
    placeholder: "none",
    alt: "image",
    backgroundColor: "black",
  }

  return (
    <>
      <StyledFilms
        id="films"
        picture={hoveredTitle}
        contactShown={props.contactShown}
        isLinkClicked={isLinkClicked}
      >
        <FilmTitles
          id="titles"
          picture={hoveredTitle}
          onMouseOver={() => toggleHover("none")}
          isLinkClicked={isLinkClicked}
        />
        <BlackLayer onMouseOver={() => toggleHover("none")} />
        <MainText
          id="main-text"
          isLinkClicked={isLinkClicked}
          contactShown={props.contactShown}
        >
          <FilmHeader />

          <ul id="filmlist">
            <p
              id="tocontact"
              onMouseOver={() => toggleHover("none")}
              onClick={props.handleClick}
            >
              Arnaud Bresson
            </p>
            {"\u2000"}
            <TransitionLink
              exit={{
                delay: 0.7,
              }}
              onClick={clickLink}
              isLinkClicked={isLinkClicked}
              to="/because-its-mercedes"
            >
              <li
                className="mercedes-link film-title"
                onClick={clickLink}
                onMouseOver={() => toggleHover("mercedes")}
                onMouseLeave={() => toggleHover("none")}
              >
                Mercedes
              </li>
            </TransitionLink>
            <span className="dash">
              {"\u2000"}
              {"\u2000"}.{"\u2000"}
              {"\u2000"}
            </span>
            <TransitionLink
              exit={{
                delay: 0.7,
              }}
              onClick={clickLink}
              isLinkClicked={isLinkClicked}
              to="/audemars-piguet"
            >
              <li
                className="audemars-link film-title"
                onClick={clickLink}
                onMouseOver={() => toggleHover("audemars")}
                onMouseLeave={() => toggleHover("none")}
              >
                Audemars Piguet
              </li>
            </TransitionLink>
            <span className="dash">
              {"\u2000"}
              {"\u2000"}.{"\u2000"}
              {"\u2000"}
            </span>
            <TransitionLink
              exit={{
                delay: 0.7,
              }}
              onClick={clickLink}
              isLinkClicked={isLinkClicked}
              to="/travis-scott-gods-country"
            >
              <li
                className="travis-link film-title"
                onClick={clickLink}
                onMouseOver={() => toggleHover("travis")}
                onMouseLeave={() => toggleHover("none")}
              >
                Travis Scott
              </li>
            </TransitionLink>
            <span className="dash">
              {"\u2000"}
              {"\u2000"}.{"\u2000"}
              {"\u2000"}
            </span>
            <TransitionLink
              exit={{
                delay: 0.7,
              }}
              onClick={clickLink}
              isLinkClicked={isLinkClicked}
              to="/kanye-balenciaga-gap"
            >
              <li
                className="kanyethree-link film-title"
                onClick={clickLink}
                onMouseOver={() => toggleHover("kanyethree")}
                onMouseLeave={() => toggleHover("none")}
              >
                Gap x Balenciaga
              </li>
            </TransitionLink>
            <span className="dash">
              {"\u2000"}
              {"\u2000"}.{"\u2000"}
              {"\u2000"}
            </span>
            <TransitionLink
              exit={{
                delay: 0.7,
              }}
              onClick={clickLink}
              isLinkClicked={isLinkClicked}
              to="/mia-popular"
            >
              <li
                className="mia-link film-title"
                onClick={clickLink}
                onMouseOver={() => toggleHover("mia")}
                onMouseLeave={() => toggleHover("none")}
              >
                M.I.A.
              </li>
            </TransitionLink>
            <br />
            <TransitionLink
              exit={{
                delay: 0.7,
              }}
              onClick={clickLink}
              isLinkClicked={isLinkClicked}
              to="/kanye-heaven-hell"
            >
              <li
                className="flatbush-link film-title"
                onClick={clickLink}
                onMouseOver={() => toggleHover("kanye")}
                onMouseLeave={() => toggleHover("none")}
              >
                Kanye West
              </li>
            </TransitionLink>
            <span className="dash">
              {"\u2000"}
              {"\u2000"}.{"\u2000"}
              {"\u2000"}
            </span>
            <TransitionLink
              exit={{
                delay: 0.7,
              }}
              onClick={clickLink}
              isLinkClicked={isLinkClicked}
              to="/flatbush-zombies-james-blake-afterlife"
            >
              <li
                className="flatbush-link film-title"
                onClick={clickLink}
                onMouseOver={() => toggleHover("flatbush")}
                onMouseLeave={() => toggleHover("none")}
              >
                Flatbush Zombies
              </li>
            </TransitionLink>
            <span className="dash">
              {"\u2000"}
              {"\u2000"}.{"\u2000"}
              {"\u2000"}
            </span>
            <TransitionLink
              exit={{
                delay: 0.7,
              }}
              onClick={clickLink}
              isLinkClicked={isLinkClicked}
              to="/laylow-nike-vapormax"
            >
              <li
                className="nike-link film-title"
                onClick={clickLink}
                onMouseOver={() => toggleHover("nike")}
                onMouseLeave={() => toggleHover("none")}
              >
                Nike
              </li>
            </TransitionLink>
            <span className="dash">
              {"\u2000"}
              {"\u2000"}.{"\u2000"}
              {"\u2000"}
            </span>
            <TransitionLink
              exit={{
                delay: 0.7,
              }}
              onClick={clickLink}
              isLinkClicked={isLinkClicked}
              to="/beadadoobee-last-day-on-earth"
            >
              <li
                className="beadadoobee-link film-title"
                onClick={clickLink}
                onMouseOver={() => toggleHover("beadadoobee")}
                onMouseLeave={() => toggleHover("none")}
              >
                Beadadoobee
              </li>
            </TransitionLink>
            <span className="dash">
              {"\u2000"}
              {"\u2000"}.{"\u2000"}
              {"\u2000"}
            </span>
            <TransitionLink
              exit={{
                delay: 0.7,
              }}
              onClick={clickLink}
              isLinkClicked={isLinkClicked}
              to="/rl-grime-arcus"
            >
              <li
                className="rlgrime-link film-title"
                onClick={clickLink}
                onMouseOver={() => toggleHover("rlgrime")}
                onMouseLeave={() => toggleHover("none")}
              >
                RL Grime
              </li>
            </TransitionLink>
            <span className="dash">
              {"\u2000"}
              {"\u2000"}.{"\u2000"}
              {"\u2000"}
            </span>
            <TransitionLink
              exit={{
                delay: 0.7,
              }}
              onClick={clickLink}
              isLinkClicked={isLinkClicked}
              to="/kanye-hurricane"
            >
              <li
                className="kanywetwo-link film-title"
                onClick={clickLink}
                onMouseOver={() => toggleHover("kanyetwo")}
                onMouseLeave={() => toggleHover("none")}
              >
                Kanye West ft. The Weeknd
              </li>
            </TransitionLink>
          </ul>
        </MainText>
        <div id="filmpreviews" onMouseOver={() => toggleHover("none")}>
          <div>
            <FilmBg
              id="mercedespic"
              className="mercedes-link film-background"
              picture={hoveredTitle}
            >
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className=" pic"
                  src="../../images/mercedes-1.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/mercedes-2.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/mercedes-3.jpg"
                />
              </div>
            </FilmBg>
            <FilmBg
              id="audemarspic"
              className="audemars-link film-background"
              picture={hoveredTitle}
            >
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className=" pic"
                  src="../../images/audemars-1.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/audemars-2.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/audemars-3.jpg"
                />
              </div>
            </FilmBg>
            <FilmBg
              id="travispic"
              className="travis-link film-background"
              picture={hoveredTitle}
            >
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className=" pic"
                  src="../../images/travis-1.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/travis-2.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/travis-3.jpg"
                />
              </div>
            </FilmBg>
            <FilmBg
              id="miapic"
              className="mia-link film-background"
              picture={hoveredTitle}
            >
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className=" pic"
                  src="../../images/mia-1.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/mia-2.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/mia-3.jpg"
                />
              </div>
            </FilmBg>
            <FilmBg
              id="kanyethreepic"
              className="kanyethree-link film-background"
              picture={hoveredTitle}
            >
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className=" pic"
                  src="../../images/kanyethree-3.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/kanyethree-2.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/kanyethree-1.jpg"
                />
              </div>
            </FilmBg>
            <FilmBg
              id="kanyetwopic"
              className="kanyetwo-link film-background"
              picture={hoveredTitle}
            >
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className=" pic"
                  src="../../images/kanyetwo-3.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/kanyetwo-2.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/kanyetwo-1.jpg"
                />
              </div>
            </FilmBg>
            <FilmBg
              id="kanyepic"
              className="kanye-link film-background"
              picture={hoveredTitle}
            >
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className=" pic"
                  src="../../images/kanye-3.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/kanye-2.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/kanye-1.jpg"
                />
              </div>
            </FilmBg>

            <FilmBg
              id="flatbushpic"
              className="flatbush-link film-background"
              picture={hoveredTitle}
            >
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/flatbush-1.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/flatbush-2.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/flatbush-3.jpg"
                />
              </div>
            </FilmBg>

            <FilmBg
              id="nikepic"
              className="nike-link film-background"
              picture={hoveredTitle}
            >
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/nike-1.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/nike-2.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/nike-3.jpg"
                />
              </div>
            </FilmBg>
            <FilmBg
              id="rlgrimepic"
              className="rlgrime-link film-background"
              picture={hoveredTitle}
            >
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/rlgrime-1.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/rlgrime-2.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className=" pic"
                  src="../../images/rlgrime-3.jpg"
                />
              </div>
            </FilmBg>
            <FilmBg
              id="beadadoobeepic"
              className="beadeadoobee-link film-background"
              picture={hoveredTitle}
            >
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/beabadoobee-1.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className="pic"
                  src="../../images/beabadoobee-2.jpg"
                />
              </div>
              <div className="pic-container">
                <StaticImage
                  {...imgsettings}
                  className=" pic"
                  src="../../images/beadadoobee-3.jpg"
                />
              </div>
            </FilmBg>
          </div>
        </div>
      </StyledFilms>
    </>
  )
}
