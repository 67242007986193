import React from "react"
import styled from "styled-components"

import PictureMobile from "./PictureMobile"
import scrollTo from "gatsby-plugin-smoothscroll"

const StyledPicturesMobile = styled.div`
  display: none;

  @media (max-width: 799px) {
    display: block;
  }
  position: relative;

  width: 100vw;
  padding: 0 0 6vh 0;
  max-width: 100vw;

  #pictureheader {
    color: black !important;
    text-decoration: underline;
    z-index: 500;
  }

  .pic-container {
    background-color: white;
    padding: 0;
  }

  h1 {
    color: black !important;
  }
`

const ToTopBtn = styled.button`
  position: relative;
  width: 100vw;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 8vh 0 0 0;
  z-index: 999;
  isolation: isolate;

  font-size: 5.5vw;
  line-height: 6vw;
  font-weight: bold;
  background: none;
  border: none;
  border-bottom: 1px solid white !important;
  text-align: center;
  padding: 0;
`

export default function PicturesDesktop(props) {
  return (
    <StyledPicturesMobile id="picturesmobile">
      <div className="pic-container">
        <PictureMobile filename="img3.jpg" />
      </div>
      <div className="pic-container">
        <PictureMobile filename="img2.jpg" />
      </div>
      <div className="pic-container">
        <PictureMobile filename="img1.jpg" />
      </div>
      <ToTopBtn id="top-link" onClick={() => scrollTo("#home")}>
        Back
      </ToTopBtn>
    </StyledPicturesMobile>
  )
}
