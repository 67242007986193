import React from "react"
import styled from "styled-components"

export const StyledTitles = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 52vh;
  z-index: 999;
  font-weight: 800;
  letter-spacing: -0.2px;
  font-size: 21px;

  .text {
    margin: 0;
    padding: 0;
    text-align: center;
    text-transform: uppercase;
  }
  @media (max-height: 500px) {
    font-size: 19px;
  }
  transition: opacity ease 1s 0s !important;
  opacity: ${props => (props.isLinkClicked ? "0 !important" : "1")};
`

export function FilmTitles(props) {
  return (
    <StyledTitles isLinkClicked={props.isLinkClicked}>
      <FilmTitlesContent picture={props.picture} />
    </StyledTitles>
  )
}

export function FilmTitlesContent(props) {
  switch (props.picture) {
    case "mercedes":
      return <div className="text">Because it's Mercedes</div>
    case "audemars":
      return <div className="text">Matthew Williams in imaginationland</div>
    case "travis":
      return <div className="text">God's Country</div>
    case "mia":
      return <div className="text">Popular</div>
    case "kanyethree":
      return <div className="text">Life of the Party</div>
    case "kanyetwo":
      return <div className="text">Hurricane</div>
    case "kanye":
      return <div className="text">Heaven and hell x gap</div>
    case "flatbush":
      return <div className="text">Afterlife</div>
    case "nike":
      return (
        <div className="text">
          laylow <span className="lowercase">x</span> vapormax
        </div>
      )
    case "beadadoobee":
      return <div className="text">Last day on earth</div>
    case "rlgrime":
      return <div className="text">Arcus</div>
    case "none":
      return <div className="text"></div>
    default:
      return null
  }
}
