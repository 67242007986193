import styled from "styled-components"
import React from "react"
import { Link } from "gatsby"

const StyledHeader = styled.button`
  color: ${props => (props.primary ? "black" : "white")} !important;
  cursor: pointer;
  position: fixed;
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  z-index: 9999;
  color: black !important;
  width: 100vw;
  font-family: kanit;
  text-align: center;
  line-height: 15px;

  .film {
    display: inline-block;
    font-weight: 400;
    width: 52px;
    cursor: initial;
    text-align: right;
  }
  .line {
    display: inline-block;
    font-weight: 200 !important;
  }
  .photo {
    display: inline-block;
    width: 130px;
    text-align: left;
  }
  font-size: 13px;
  text-align: center;
  line-height: 14px;

  &:hover {
    .word {
      font-weight: 200;
      &:hover {
        font-weight: 400 !important;
      }
    }

    .film {
    }
  }
  @media (min-width: 1300px) {
    font-size: 16px;
    line-height: 18px;
  }

  @media (min-width: 1500px) {
    font-size: 18px;
    line-height: 18px;
  }

  top: 10px;
  left: 0;
  right: 0;
`
export default function FilmHeader(props) {
  return (
    <StyledHeader id="header">
      <span className="film word">Film</span>
      <span className="line">
        {"\u2000"}
        {"\u2000"}|{"\u2000"}
        {"\u2000"}
      </span>
      <Link to="/pictures">
        <span className="word photo"> Photography</span>
      </Link>
    </StyledHeader>
  )
}
